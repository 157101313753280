








































































































import { Component, Mixins, Watch } from 'vue-property-decorator'
import { VueConstructor } from 'vue'
import { IModal, ModalType } from '@movecloser/front-core'

import { AddressData, Country } from '../../../../../contexts'
import FormErrorsMixin from '../../../../../support/mixins/FormErrors.mixin.vue'
import { Inject, logger } from '../../../../../support'

import {
  authSSOParamsKey,
  authSSOSelectedProvider,
  authSSOSessionId
} from '../../../../auth/config/auth'
import { AuthControlServiceType, IAuthControl, SignupFormPayload } from '../../../../auth/contracts'
import { CompanyRepositoryType, ICompanyRepository } from '../../../../profile/repositories/company'
import { LoginForm } from '../../../../auth/organisms/LoginForm'
import { AuthMixin, SSOAuthMixin } from '../../../../auth/shared'
import { RouteNames as AuthRouteNames } from '../../../../auth/routes'
import { UserMixin } from '../../../../profile/shared'
import { Form, ValidationRules } from '../../../../shared/molecules/Form'

import { CartMutationTypes, CheckoutPayload } from '../../../contracts'
import { GuestDetailsFormData, SSOContextMode } from '../../../molecules/GuestDetailsFormFieldset'
import {
  GuestDetailsFormFieldsetTwo
} from '../../../molecules/GuestDetailsFormFieldset/GuestDetailsFormFieldsetTwo.vue'
import GuestDetailsFormFieldsetSSO
  from '../../../molecules/GuestDetailsFormFieldset/GuestDetailsFormFieldsetSSO.vue'
import RadioSwitch from '../../../molecules/FormContextSwitch/RadioSwitch.vue'

import { AddressSelector } from '../../AddressSelector'
import { LoginAndRegister } from '../../BuyerContextSwitch/versions/LoginAndRegister.vue'

import { CustomerDetailsStep } from '../CustomerDetailsStep.vue'
import {
  AddressMode,
  guestDetailsFieldsetComponentsRegistry,
  GuestDetailsFieldsetType
} from '../CustomerDetailsStep.config'
import {
  INipValidationService,
  NipValidationServiceType
} from '../../../../shared/services/nip-validation'
import BaseCompany from '../../../../shared/mixins/base-company.mixin'
import {
  translateCountryToCountrySelectOption
} from '../../../../profile/organisms/ChangeAddressForm/ChangeAddressForm.helpers'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<CustomerDetailsStepValidate>({
  name: 'CustomerDetailsStepValidate',
  components: {
    AddressSelector,
    BuyerContextLoginAndRegister: LoginAndRegister,
    Form,
    GuestDetailsFormFieldsetTwo,
    GuestDetailsFormFieldsetSSO,
    LoginForm,
    RadioSwitch
  },
  mounted (): void {
    // Save the first payload.
    this.checkoutPayload = this.payload
  }
})
export class CustomerDetailsStepValidate extends Mixins(BaseCompany, CustomerDetailsStep, AuthMixin, FormErrorsMixin, UserMixin, SSOAuthMixin) {
  @Inject(AuthControlServiceType)
  protected readonly authControl!: IAuthControl

  @Inject(CompanyRepositoryType)
  protected readonly companyRepository!: ICompanyRepository

  @Inject(ModalType)
  private modalConnector!: IModal

  @Inject(NipValidationServiceType)
  protected readonly nipValidationService!: INipValidationService

  public addressMode: AddressMode = AddressMode.Preview
  public mode: string = 'login'

  public selectedUserAddressId: number | null = null
  public shouldObserve: boolean = false
  public hasCompanyEmailError: boolean = false
  public ssoMode: string = SSOContextMode.Guest

  protected checkoutPayload: CheckoutPayload | null = null

  public get canEditBillingAddress (): boolean {
    if (!this.user) {
      return false
    }
    return !this.user.isVerified
  }

  public get fieldsetComponent (): VueConstructor {
    if (!(this.guestDetailsFieldsetType in guestDetailsFieldsetComponentsRegistry)) {
      return guestDetailsFieldsetComponentsRegistry[GuestDetailsFieldsetType.Two]
    }
    return guestDetailsFieldsetComponentsRegistry[this.guestDetailsFieldsetType]
  }

  public get guestDetailsData () {
    return {
      address: this.payload.address,
      user: this.payload.user,
      acceptContent: this.payload.acceptContent
    }
  }

  public set guestDetailsData (values: any) {
    for (const [key, value] of Object.entries(values)) {
      this.onChange(key, value)
    }
  }

  /**
   * Information whether user has been authenticated using SSO
   */
  public get ssoAuthenticated (): boolean {
    return this.$store.getters['checkout/ssoAuthenticated']
  }

  /**
   * Information whether user must accept consents to finish SSO authentication
   */
  public get ssoConsentsRequested (): boolean {
    return this.$store.getters['checkout/ssoConsentsRequest']
  }

  /**
   * Switch addressMode to passed-in value.
   */
  public addressModeTo (mode: AddressMode): void {
    this.addressMode = mode
  }

  /**
   * Handles the @edit event of `AddressSelector` component.
   */
  public async onAddressEdit (payload: { id: number; address: AddressData }): Promise<void> {
    this.mapUserAddressToCheckoutPayload(payload.address, this.hasRegionField)

    this.addressMode = AddressMode.Edit
    this.selectedUserAddressId = payload.id
  }

  /**
   * Handles the @onMounted event of `AddressSelector` component.
   */
  public onAddressMounted (payload: { id: number; address: AddressData }): void {
    this.mapUserAddressToCheckoutPayload(payload.address, this.hasRegionField)
  }

  /**
   * Handles @onAddress event of `AddressSelector` component.
   */
  public async onAddressSelection (payload: { id: number; address: AddressData } | undefined): Promise<void> {
    if (!payload) {
      return
    }

    this.$emit('saving', true)

    this.mapUserAddressToCheckoutPayload(payload.address, this.hasRegionField)

    try {
      await this.profileService.updateDeliveryAddress(payload.id, {
        ...payload.address,
        defaultBilling: true
      })

      if (!this.authService.token) {
        return
      }

      const user = await this.profileService.getUserDetails(this.authService.token.accessToken)
      this.authService.setUser(user)
      this.$store.commit('profile/SET_USER', user)
    } catch (e) {
      logger(e, 'error')
    } finally {
      this.$emit('saving', false)
    }
  }

  /**
   * Handles the cancellation of editing of selected address.
   */
  public onEditCancel (): void {
    this.addressMode = AddressMode.Preview
    this.errors = null
    if (this.checkoutPayload) {
      this.onChange('user', this.checkoutPayload.user)
      this.onChange('address', this.checkoutPayload.address)
    }
  }

  /**
   * Handle forgot password event.
   */
  public onForgotPassword (): void {
    this.$router.push({
      name: `auth.${AuthRouteNames.RequestResetPassword}`
    })
  }

  /**
   * Handles update of user's selected address.
   */
  public async onEditAddress (): Promise<void> {
    try {
      if (!this.selectedUserAddressId) {
        return
      }

      await this.onUserAddress(this.selectedUserAddressId, false)
    } catch (e) {
      logger((e as Error).message, 'error')
    } finally {
      this.$emit('saving', false)
    }
  }

  /**
   * Handles successful login
   */
  public onSuccess (): void {
    this.shouldObserve = true
  }

  public updateGuestModel (key: string, value: unknown) {
    this.guestDetailsData = {
      ...this.guestDetailsData,
      [key]: value
    }
  }

  @Watch('user')
  protected onUserChange () {
    if (this.shouldObserve) {
      this.eventBus.emit('app:authorization.login', this.user)
    }
  }

  @Watch('company')
  protected async onCompanyUpdate (): Promise<void> {
    if (this.user && this.company) {
      const billingAddress = this.user.addresses.find((address) => address.defaultBilling)
      if (!billingAddress) {
        await this.$router.push({
          name: `auth.${AuthRouteNames.CompanyCreate}`,
          query: { hasBilling: 'false' }
        })
      }
    }
    this.$emit('saving', false)
  }

  public async submitValidation (): Promise<void> {
    this.hasCompanyEmailError = false

    if (!this.cart) {
      return
    }

    const validators = this.hasInvoice
      ? Object.prototype.hasOwnProperty.call(this.validatorsConfig, 'company') ? this.validatorsConfig.company : undefined
      : Object.prototype.hasOwnProperty.call(this.validatorsConfig, 'company') ? this.validatorsConfig.guest : undefined

    if (
      !CustomerDetailsStep.isValidStep(
        this.translateformDataToValidationPayload(this.guestDetailsData),
        this.$t.bind(this),
        this.$i18n.locale,
        this.setErrors,
        validators ? validators as ValidationRules : undefined
      )
    ) {
      return
    }

    this.$emit('saving', true)

    try {
      if (this.payload.isSignupRequested) {
        if (!this.hasInvoice) {
          const tokenModel = await this.authControl.signup({
            ...this.payload.user,
            subscribeToNews: false
          } as SignupFormPayload)

          this.authService.setToken(tokenModel.toAuthToken())

          const cartId = await this.initCartOnRegister(this.cart.id)

          if (!this.authService.token) {
            return
          }

          await this.profileService.storeDeliveryAddress({
            firstName: this.payload.user.firstName ?? '',
            lastName: this.payload.user.lastName ?? '',
            postalCode: this.payload.address.postalCode,
            city: this.payload.address.city,
            company: null,
            street: this.payload.address.address,
            defaultBilling: true,
            defaultShipping: false,
            region: this.payload.address.region,
            countryCode: this.payload.address.country,
            phoneNumber: this.payload.address.phone
          })

          await this.profileService.getUserDetails(this.authService.token.accessToken).then((user) => {
            this.authService.setUser(user)
            this.$store.commit('profile/SET_USER', user)
          })

          this.$nextTick(this.continueSubmit.bind(this, cartId))
        } else {
          const isCompanyEmailAvailable = await this.companyRepository.isCompanyEmailAvailable(this.payload.user.email)

          if (!isCompanyEmailAvailable) {
            this.hasCompanyEmailError = true
          } else {
            if (Object.prototype.hasOwnProperty.call(this.guestDetailsData.user, 'vatId') && (this.guestDetailsData.user as GuestDetailsFormData).vatId) {
              const tokenModel = await this.companyRepository.shortCreateCompany({
                email: this.guestDetailsData.user.email,
                password: this.guestDetailsData.user.password,
                passwordConfirmation: this.guestDetailsData.user.passwordConfirmation,
                vatTaxId: this.guestDetailsData.user.vatId
              })

              this.authService.setToken(tokenModel.toAuthToken())

              await this.$router.push({
                name: `auth.${AuthRouteNames.CompanyCreate}`,
                query: { vatId: (this.guestDetailsData.user as GuestDetailsFormData).vatId }
              })
            }
          }
        }
      } else {
        if (!this.payload.isUserLoggedIn) {
          await this.checkoutService.setGuestEmail(this.cart.id, this.payload.user.email)
        }

        await this.continueSubmit(this.cart.id)
      }
    } catch (e) {
      logger(e, 'warn')
      this.$emit('error', (e as Error).message)
    } finally {
      this.$emit('saving', false)
    }
  }

  public translateformDataToValidationPayload (input: CheckoutPayload) {
    if (this.ssoMode === SSOContextMode.Company) {
      return {
        acceptContent: input.acceptContent,
        vatId: (input.user as GuestDetailsFormData).vatId ?? ''
      }
    }

    return this.hasInvoice ? {
      email: input.user.email,
      password: input.user.password,
      passwordConfirmation: input.user.passwordConfirmation,
      vatId: (input.user as GuestDetailsFormData).vatId ?? ''
    } : {
      address: input.address.address,
      acceptContent: input.acceptContent,
      city: input.address.city,
      country: input.address.country,
      region: input.address.region,
      email: input.user.email,
      firstName: input.user.firstName,
      isSignupRequested: input.isSignupRequested ?? false,
      lastName: input.user.lastName,
      password: input.user.password,
      passwordConfirmation: input.user.passwordConfirmation,
      phone: input.address.phone,
      postalCode: input.address.postalCode
    }
  }

  /**
   * Adds / updates new address to user
   */
  protected async onUserAddress (id?: number, isNew: boolean = true): Promise<void> {
    let userAddress: AddressData = {
      firstName: this.guestDetailsData.user.firstName,
      lastName: this.guestDetailsData.user.lastName,
      street: this.guestDetailsData.address.address,
      countryCode: this.guestDetailsData.address.country,
      phoneNumber: this.guestDetailsData.address.phone,
      postalCode: this.guestDetailsData.address.postalCode,
      city: this.guestDetailsData.address.city,
      company: this.guestDetailsData.address.company,
      vatId: this.guestDetailsData.address.invoice?.nip,
      defaultBilling: true,
      defaultShipping: false
    }

    if (this.hasRegionField && this.guestDetailsData.address.region) {
      userAddress = {
        ...userAddress,
        region: this.guestDetailsData.address.region
      }
    }

    let validators = this.hasInvoice
      ? Object.prototype.hasOwnProperty.call(this.validatorsConfig, 'company') ? this.validatorsConfig.company : undefined
      : Object.prototype.hasOwnProperty.call(this.validatorsConfig, 'guest') ? this.validatorsConfig.guest : undefined

    if (this.ssoMode === SSOContextMode.Company && Object.prototype.hasOwnProperty.call(this.validatorsConfig, 'sso')) {
      validators = this.validatorsConfig.sso
    }

    if (
      !CustomerDetailsStep.isValidStep(
        this.translateformDataToValidationPayload(this.guestDetailsData),
        this.$t.bind(this),
        this.$i18n.locale,
        this.setErrors,
        validators ? validators as ValidationRules : undefined
      )
    ) {
      return
    }

    this.$emit('saving', true)

    try {
      if (this.ssoConsentsRequested && !this.ssoAuthenticated) {
        const ssoProvider = localStorage.getItem(authSSOSelectedProvider)
        const ssoParams = localStorage.getItem(authSSOParamsKey)
        const ssoSessionId = localStorage.getItem(authSSOSessionId)

        if (!ssoParams || !ssoProvider || !ssoSessionId) {
          return
        }

        const validationResult = await this.validateResellerId(this.guestDetailsData.user.vatId)

        if (validationResult.errorMessage) {
          this.showToast(validationResult.errorMessage, 'danger')
          return
        }

        const response = await this.authControl.setSocialCallback(
          JSON.parse(ssoParams),
          ssoProvider,
          JSON.parse(ssoSessionId).id,
          true,
          this.ssoMode === SSOContextMode.Company ? this.guestDetailsData.user.vatId : undefined
        )

        if (response && response.token) {
          await this.authService.setToken(response.token.toAuthToken())
          this.$store.commit(CartMutationTypes.SetSSOAuthenticated, true)
          this.$store.commit(CartMutationTypes.SetSSOConsentsRequest, false)
        }
      }

      if (this.ssoMode === SSOContextMode.Company) {
        await this.$router.push({
          name: `auth.${AuthRouteNames.CompanyCreate}`,
          query: { hasBilling: 'false' }
        })
        return
      }

      if (isNew) {
        await this.profileService.storeDeliveryAddress(userAddress)
      } else {
        if (id) {
          await this.profileService.updateDeliveryAddress(id, userAddress)
        }
      }
    } catch (e) {
      this.$emit('error', (e as Error).message)
      this.$emit('saving', false)
    }

    if (!this.authService.token || this.ssoMode === SSOContextMode.Company) {
      this.$emit('saving', false)
      return
    }

    try {
      await this.profileService.getUserDetails(this.authService.token.accessToken).then((user) => {
        this.authService.setUser(user)
        this.$store.commit('profile/SET_USER', user)
      })

      this.addressMode = AddressMode.Preview
    } catch (e) {
      this.$emit('error', (e as Error).message)
    } finally {
      this.$emit('saving', false)
      if (this.ssoConsentsRequested && !this.ssoAuthenticated) {
        this.nextStep()
      }
    }
  }

  /**
   * Translates & updates user address for checkout payload
   */
  protected mapUserAddressToCheckoutPayload (payload: AddressData, hasRegion: boolean = false): void {
    const { street, company, city, postalCode, vatId, phoneNumber, countryCode } = payload
    const { lastName, firstName } = payload

    this.updateValues({
      address: {
        address: street,
        billingAddress: {
          address: street,
          postalCode,
          city
        },
        country: countryCode,
        company: !company ? '' : company,
        city,
        invoice: {
          company: !company ? '' : company,
          nip: vatId
        },
        postalCode,
        region: hasRegion ? payload.region : null,
        phone: phoneNumber
      },
      user: {
        email: this.payload.user.email,
        firstName,
        lastName
      }
    })
  }

  /**
   * Validated resellerId with CEIDG database
   * @private
   */
  private async validateResellerId (vatTaxId: string): Promise<{ errorMessage: string | null }> {
    try {
      await this.nipValidationService.validateCompanyVatId(vatTaxId)
      return { errorMessage: null }
    } catch (e) {
      logger(e, 'warn')
      return { errorMessage: (e as Error).message }
    }
  }

  @Watch('mode')
  protected onModeChange (): void {
    this.$emit('error', null)
  }
}

export default CustomerDetailsStepValidate
